<template>
    <div class="popup">
        <slot></slot>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>
    .popup {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-evenly;
        width: 15em;
        height: auto;
        font-size: 1.5em;
        //text-align: center;
        line-height: 1.5;
        //background-color: #1a0e39;
        box-shadow: 0 2px 4px gray;
        padding: 1.7em;

    }
</style>
